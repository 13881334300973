import React, { Component } from 'react'
import Img from 'gatsby-image'
import { withNamespaces } from 'react-i18next'
import { StyleSheet, css } from 'aphrodite'
import { StaticQuery, graphql } from 'gatsby'
import { Container, Row, Col } from 'react-grid-system'

import {
  Section,
  Paper,
  ImageBasic,
  MenuHorizontal,
  MenuItem,
  MediaQueries,
} from 'inno-components'
import { Material } from 'inno-components/build/Icons'

import Classes from '../../styles/classes'
import Layout from './Layout'
import Config from '../../Config'
import Contact from '../home/Contact'

import Link from '../Link'
import pagesInfos from '../../config/i18n/pagesInfos'
import Footer from './Footer'
import cn from 'classnames'

import Menu from './Menu'

const styles = StyleSheet.create({
  menu: {
    alignItems: 'center',
    backgroundColor: '#000',
    borderRadius: 0,
    padding: '3px',
    zIndex: 9,
  },
  logo: {
    height: 'auto',
    width: '150px',
  },
  link: {
    display: 'block',
    color: '#FFF',
    textAlign: 'center',
    fontWeight: 'bold',
    textDecoration: 'none',
    fontSize: '1.2em',
    cursor: 'pointer',
    transition: 'color 0.5s',
    margin: '1em 0.5em',
    ':hover': {
      color: Config.colors.secondary,
    },
  },
  active: {
    fontWeight: 'bold',
    color: Config.colors.secondary,
  },
  section: {
    backgroundColor: '#fff',
  },
  title: {
    fontSize: '1.5em',
    lineHeight: '1.3em',
    color: Config.colors.primary,
    textTransform: 'uppercase',
    [MediaQueries.small]: {
      fontSize: '2em',
      lineHeight: '1.5em',
    },
  },
  logoContainer: {
    display: 'block',
    padding: '1em 2em',
    width: '200px',
    [MediaQueries.small]: {
      padding: '1em 4em',
      width: '100%',
    },
  },
  logo: {
    display: 'block',
    width: '150px',
    height: 'auto',
  },
  icon: {
    color: '#FFF',
  },
  left: {
    width: '100%',
    padding: '0.5em',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

const LinkComponent = ({ link, active }) => {
  return (
    <Link
      page={pagesInfos.ComponentIndex}
      className={css(styles.link, active && styles.active)}
      query={'#' + link.name}
    >
      {link.label}
    </Link>
  )
}

class SecondaryLayout extends Component {
  handleItemClick = (e, { name }) => this.setState({ activeItem: name })
  render() {
    return (
      <StaticQuery
        query={graphql`
          query SecondaryLayoutImageQuery {
            logo: file(relativePath: { eq: "home/logo.png" }) {
              childImageSharp {
                fluid(maxWidth: 800, maxHeight: 239) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        `}
        render={data => {
          const links = [
            MenuItem({
              anchor: 'notre-entreprise',
              name: 'notre-entreprise',
              label: 'Notre Entreprise',

              LinkComponent: LinkComponent,
            }),
            MenuItem({
              anchor: 'vos-campagnes',
              name: 'vos-campagnes',
              label: 'Vos Campagnes',

              LinkComponent: LinkComponent,
            }),
            MenuItem({
              anchor: 'contact',
              name: 'contact',
              label: 'Contact',

              LinkComponent: LinkComponent,
            }),
            MenuItem({
              anchor: 'references',
              name: 'references',
              label: 'Références',

              LinkComponent: LinkComponent,
            }),
          ]

          return (
            <Layout>
              <Menu isDisplayFromHome={false} />

              <Section className={css(Classes.section, styles.section)}>
                <Container>
                  <Row>
                    <Col>
                      <h1 className={css(styles.title)}>
                        {this.props.subtitle}
                      </h1>
                    </Col>
                  </Row>
                </Container>
              </Section>
              {this.props.children}
              <Contact isDisplayFromHome={false} page={this.props.subtitle}/>
              <Footer />
            </Layout>
          )
        }}
      />
    )
  }
}

export default SecondaryLayout
